
@media (min-width: 768px){
    .blocsLikMobile{
        display: none;
    }
}
.mobileMenuDeconnection{
    width: 100%;
    /* border-top: 1px solid #9AA6B7; */
    /* background: transparent linear-gradient(259deg, #C10058 0%, #281B59 100%) 0% 0% no-repeat padding-box; */
}
.deconnaxionButonMenu{
    font-size: 14px;
}
@media (max-width: 512px){
    .sidebar .nav-link span {
        margin-left: 20px !important;
        margin-right: 0px !important;
    }
}
@media (min-width: 801px){
    .mobileMenuDeconnection {
        display: none;
        background-color: #fff !important;
    }
    .monCompteMenu{
        display: none;
    }
}