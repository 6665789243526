// Variable overrides
$primary:       #c10058 !default;
$body-bg:    #FFFFFF;

//$body-color: #0f0;
//$primary:       #c10058 !default;
//$secondary:     #0f0 !default;
//$success:       #0f0 !default;
//$info:          #0f0 !default;
//$warning:       #0f0 !default;
//$danger:        #0f0 !default;
//$light:         #0f0 !default;
//$dark:          #0f0 !default;
//
//$theme-colors: () !default;
//// stylelint-disable-next-line scss/dollar-variable-default
//$theme-colors: map-merge(
//                (
//                        "primary":    $primary,
//                        "secondary":  $secondary,
//                        "success":    $success,
//                        "info":       $info,
//                        "warning":    $warning,
//                        "danger":     $danger,
//                        "light":      $light,
//                        "dark":       $dark
//                ),
//                $theme-colors
//);
