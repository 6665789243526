/*********************************************************** commun ***********************************************************/
//fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Quicksand');

body{
  font-family: Montserrat;
}
.w100 {
  font-weight: 100;
}
.w200 {
  font-weight: 200;
}
.w300 {
  font-weight: 300;
}
.w400 {
  font-weight: 400;
}
.w500 {
  font-weight: 500;
}
.w600 {
  font-weight: 600;
}
.w700 {
  font-weight: 700;
}
.wBold {
  font-weight: bold;
}
//link
a {
  text-decoration: none !important;
}
.font-quicksand-class{
  font-family: Quicksand;
}
//preloader
.preloader{
  background: url("../src/assets/preloader/preloader.gif");
  width: 30px !important;
  height: 30px;
  background-repeat: no-repeat;
  background-size: contain;
}
//colors
$primary: #c10058;
$secondary: #50E3C2;
$colorTxt1: #1F3149;
$colorTxt2: #9AA6B7;
$colorBackground: #EFF2FF;
$colorGreenBack: #00E5AC;
$colorGreenBorder: #28C49D;
$alertButton: #CC1A1A;
//errors
.error-message{
  color: $alertButton;
}
//titles
.main-primary-title{
  text-transform: lowercase;
  font-size: 32px;
  font-weight: bold;
}
.main-primary-title::first-letter{
  text-transform: capitalize !important;
}
.main-secondary-title{
  font-size: 20px;
  font-weight: bold;
  color: $colorTxt2;
  text-transform: lowercase;
}
.main-secondary-title::first-letter{
  text-transform: capitalize !important;
}
.blocs-main-title{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
  text-transform: lowercase;
  padding-left: 20px !important;
}
.blocs-main-title::first-letter{
  text-transform: capitalize !important;
}
.blocs-secondary-title{
  font-size: 15px;
  font-weight: bold;
  color: $colorTxt2;
  margin-bottom: 0px;
  padding-left: 20px !important;
}
//scroll main hidden
.main::-webkit-scrollbar { width: 0 ! important }
.main { overflow: -moz-scrollbars-none; }
.main { -ms-overflow-style: none; }
//app body
.app-body {
  color: $colorTxt1;
  margin-top: 160px !important;
}
//green bar
.green-bar{
  width: 100px;
  background-color: $colorGreenBack;
  height: 2px;
}
.inputs{
  padding: 10px 15px;
  margin-bottom: 10px;
  color: $colorTxt2;
  border-radius: 23px;
  border: 1px solid $colorTxt2;
  width: 100%;
}
button:focus {
  outline: 0px;
}
.inputs:focus {
  outline: 0px;
}
.input-radio{
  height: 25px;
  width: 25px;
}
.input-radio:checked:after {
  background-color:$colorGreenBack;
}
.span-padding{
  padding-bottom: 10px;
}
.paragraph-form{
  text-align: justify;
  font-size: 12px;
  padding-left: 5px;
}
.ul-without-bullet{
  list-style: none;
  padding-left: 0px;
  li{
    padding-bottom: 10px;
  }
  span{
    padding-left: 20px;
  }
  .second-span{
    padding-left: 40px;
  }
}
.ptop{
  padding-top: 20px;
}
.promo-offer{
  display: inline-flex;
  justify-content: center;
  padding-top: 10px;
}
.promo-offer input{
  padding-left: 20px;
  padding-right: 20px;
  background: #ffffff;
  border:0px
}
.promo-offer span{
  background: #c10058;
  border-radius: 0px 15px 15px 0;
  padding-left: 15px;
  padding-right: 20px;
  font-size: 20px;
  color: #fff;
}
//currsor pointer
.curssorPointer{
  cursor: pointer;
}
.curssorPointer:hover{
  transform: scale(1.01)
}
.image-container{
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  img{
    width: 100px;
    height: 100px;
    border: 1px solid $colorTxt2;
    border-radius: 50px;
  }
}
.label-file {
  cursor: pointer;
  color: $colorTxt1;
  text-decoration: underline;  
}
.label-file:hover {
  color: $primary;
}
.input-file {
  display: none;
}
.profil-form{
  label{
    padding-top: 20px;
  }
  button{
    margin-top: 20px;
  }
}
//toggle in header
.toggle-in-header{
  border-radius: 25px;
  padding: 10px 40px;  
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  p{
    cursor: pointer;
    border-bottom: 1px solid rgba(154,166,183,1);
    margin: 0;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 13px;
  }
  p:hover{
    opacity: 0.8;
  }
  p:last-child{
    border-bottom: 0;
  }
}
.toggle-in-header-with-back{
  background: transparent linear-gradient(259deg, #C10058 0%, #281B59 100%) 0% 0% no-repeat padding-box;
  color: #ffffff;
  p{
    cursor: pointer;
    border-bottom: 1px solid rgba(255,255,255,0.23);
  }
}
.image-offer{
  text-align: center;
  img {
    max-width: 400px;
    width: auto;
    height: 200px;
  }
}
.show-responsive{
  display: none;
}
.hide-responsive{
  display: block;
}
.icons-bloc{
  text-align: left;
}
.icon-cards{
  text-align: center;
}
.display-responsive{
  display: flex;
}
.avancemt-block{
  display: block;
}
.access-formation-title{
  padding-left: 0px !important; 
  padding-top: 5px;
  padding-bottom: 0px;
}
.hide-table-responsive{
  display: table;
}
.show-table-responsive{
  display: none;
}
/*********************************************************** buttons ***********************************************************/
.alert-button{
  background-color: $alertButton;
  text-align: center;
  color: #fff;
  font-size: 16px;
  border-radius: 10px;
  padding: 5px 80px 5px 80px;
  cursor: pointer;
  border: 0px;
  margin-left: 50px;
}
.alert-button:hover{
  background-color: $primary;  
}
.green-button{
  text-align: center;
  color: #fff !important;
  background-color: $colorGreenBack;
  border: 3px solid $colorGreenBorder;
  border-radius: 30px;
  padding: 5px 70px 5px 70px;
  margin-right: auto !important;
  margin-left: auto !important;
  cursor: pointer;
}
.payment-button{
  text-align: center;
  color: $colorGreenBorder !important;
  background-color: transparent;
  border: 3px solid $colorGreenBorder;
  border-radius: 30px;
  padding: 5px 80px 5px 80px;
  margin-right: auto !important;
  margin-left: auto !important;
  cursor: pointer;
  width: 100%;
}
.payment-button:hover{
  color:#ffffff !important;
  background-color: $colorGreenBack;
}
.download-button{
  text-align: center;
  color: #C10058 !important;
  background-color: transparent;
  border: 3px solid #C10058;
  border-radius: 30px;
  padding: 5px 50px 5px 50px;
  margin-right: auto !important;
  margin-left: auto !important;
  cursor: pointer;
}
/*********************************************************** blocs ***********************************************************/
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  display: block;
}

.modal-main {
  position:fixed;
  background: white;
  width: 60%;
  height: auto;
  top: 30%;
  left:20%;
  right: 20%;
  text-align: center;
  //transform: translate(-50%,-50%);
}
.modal-main:hover{
  transform: scale(1) !important;
}

.bloc-commun-style{
  background-color: #fff;
  border-radius: 20px;
  padding: 40px;
  margin: 20px 0;
  align-items: center;
  opacity: 1;
  word-break: break-word;
  width: 100%;
}
.bloc-commun-style:hover{
  transform: scale(1.01)
}
.bloc-main-head{
  margin: 0 0 40px 0;
  justify-content: space-between;
  display: flex;
}
.bloc-icon{
  background-color: $alertButton;
  padding-right: 20px !important;
}
.blocs-link{
  text-decoration: underline;
  cursor: pointer;
}
.blocs-link:hover{
  transform: scale(1.01);
  font-weight: bold;
}
.bloc-main-secondary-head{
  align-items: center;
}
.bloc-main-secondary-head div{
  padding-right: 0px !important;
}
.blocs-results{
  text-align: center;
}
.blocs-results span{
  font-size: 85px;
  font-weight: bold;
}
.blocs-normal-text{
  padding-top: 25px;
}
.bloc-application-mobile{
  width: 250px;
  height: 230px;
  background: #EFF2FF 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 1;
  text-align: center;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}
.bloc-application-mobile p{
  font-weight: normal;
  font-size: 18px;
  color: $colorTxt2;
  margin: 0;
}
.bloc-application-mobile .icons-se{
  display: flex;
  justify-content: center;

}
.bloc-application-mobile .icons-se img{
  background-color: #fff;
  padding: 10px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: 15px;
}
.blocs-feedbacks{
  padding-top: 75px;
}
/**** header ****/
.app-header{
  height: 160px;
  right: 25px;
  border-bottom: none;
  .navbar-toggler{
    display: none;
  }
  .navbar-brand {
    width: 20%;
    height: 100%;
    background-color: #fff;
  }
  .navbar-brand img{
    width: 170px;
  }
  .navbar-nav{
    height: 130px;
    width: 80%;
    padding: 0 30px;
    margin-top: 30px;
    border-radius: 40px 40px 0px 0;
    justify-content: space-between;
    background-color: $colorBackground;
  }
  .md-avatar {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    margin: 0 10px;
    border: solid 1px;
  }
}
.custom-link-nav{
  font-size: larger;
}
.results-list{
  padding-left: 0;
  li{
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #9AA6B7;
  }
  li .result-number{
    font-size: 25px;
  }
  li:first-child {
    border-top: 0px;
  }  
  li:last-child {
  }
  .good-result{
    color: #1CD31C;
  }  
  .danger-result{
    color: $alertButton;
  }
}
.achats-list{
  font-size: 18px;
  font-family: Quicksand;
  tr{      
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    border-top: 1px solid #9AA6B7;
  }
  tr:first-child{
    color: $colorTxt2 !important;
    border-top: 0px;

  }
  tr td{
    width: 20%;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  tr td:first-child{
    width: 50%; 
    font-weight: bold;
  }
  tr td:last-child{width: 10%; cursor: pointer;}

}
.bloc-start-end-formation{
  padding-top: 30px;
  padding-bottom: 0px;
  justify-content: space-between;
}
.bloc-button-formation{
  justify-content: center;
  .green-button:first-child{
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.padding-left-responsive{
  padding-left: 15px;
}
.with-border-left div{
  padding-left: 20px;
  padding-right: 20px;
  border-right: 1px solid $colorTxt2;
  height: 280px;
  width: 2px;
}
.bloc-achat{
  .blocs-main-title{
    padding-left: 0 !important;
  }
  ul{
    padding: 30px 0 30px 0;
    font-size: 13px;
  }
  li{
    list-style: none;
    padding-bottom: 16px;
  }
  li span{
    padding-left: 20px;
  }
  p{
    text-align: center;
  }
  .prix{
    font-size: 70px;
  }
}
.bloc-achat-hours .bloc-main-secondary-head .blocs-main-title::first-letter{
  text-transform: uppercase;
}

.bloc-achat-hours .bloc-main-secondary-head .blocs-main-title{
  font-size: 15px !important;
  text-transform: lowercase;
  text-align: center;
  padding-left: 0px !important;
}


.bloc-offres{
  height: 400px;
  padding: 0;
  cursor: pointer;
  
  .offre-partenaire-image{
    border-radius: 20px 20px 0px 0px;
    background-repeat: no-repeat !important;
    -webkit-background-size: 100% auto !important;
    -moz-background-size: 100% auto !important;
    -o-background-size: 100% auto !important;
    background-size: 100% auto !important;
    height: 60%;
  }
  .offre-partenaire-links{
    cursor: pointer;
    padding: 20px;
    height: 40%;
    .offre-partenaire-link-2{
      padding-left: 20px !important;
    }
  }
}
.bloc-simulation{
  padding: 50px;
  h3{
    font-size: 18px;
  }
  div{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  label{
    padding-left: 10px;
    font-size: 14px;
    vertical-align: super;
    text-align: justify;
  }
}
.horraire-table{
  tr td{
    padding: 5px;
  }
  tr td:first-child{
    border-right: 1px solid #9AA6B7;
  }
  tr td:last-child{
    padding-left: 40px;
  }
}
.payment-card-content {
  width: 100%;
  .title-card {
    text-transform: lowercase;
    color: #c10058;
    font-weight: bold;
    font-size: 30px;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}
//stage bloc style
.stage-bloc{
  padding-left: 0px !important;
  padding-right: 0px !important;

  .icon-stage{
    padding: 5px;
    border-radius: 7px;
    background:transparent linear-gradient(46deg, #281B59 0%, #C10058 100%) 0% 0%;
  }
  .places-restantes{
    font-size: 10px;
    margin-left: 5px;
    padding: 5px 10px;
    background-color: rgba(40,196,157,0.15);
    border-radius: 12px;
    color:$colorTxt1;
    width: 100%;
  }  
  .places-restantes-danger{
    width: 100%;
    font-size: 10px;
    margin-left: 5px;
    padding: 5px 10px;
    background-color: rgba(255,0,0,0.15);
    border-radius: 12px;
    color: $alertButton;
  }
  .icons-and-infos{
    font-size: 12px !important;
    color: #9AA6B7;
    justify-content: space-between;
    padding-left: 65px;
    padding-top: 20px;
    word-break: break-word;
  }
  font{
    padding-left: 5px;    
    vertical-align: middle;
  }
}
//bloc categories
.bloc-categories p{
  background-color: #ffffff;
  padding: 30px;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
}
.bloc-categories p:hover{
  transform: scale(1.01);
}
//bloc left payement
.bloc-left-payement{
  h2 { font-size: 16px; font-weight: bold;}
  h3 { font-size: 12px;}
}
.payment-is-stage p{
  background-color: $alertButton;
  border-radius: 20px;
  padding: 10px 50px;
  color: #fff;
}
/*********************************************************** sidebar ***********************************************************/
html:not([dir="rtl"]) .sidebar {
  margin-left: 0%;
}
@media (min-width: 1200px) {
  .app-header{
    right: 50px !important;
  }
  .main{
    right: 50px !important;
  }
  .sidebar{
    left: -50px !important;
    padding-left: 25px !important;
  }
}
@media (max-width: 991.98px){
  .app-header .navbar-brand {
    position: absolute;
    top: 0;
    left: 0%;
    margin-left: 0px;
  }
}
.sidebar {
  width: 20% !important;
  background-color: #fff;
  height: calc(100vh - 160px) !important;
  left: -25px;
  padding-left: 0px;
  .nav{
    width: 100%;
  }
  .sidebar-nav{
    width: 100%;
  }
  .nav-link {
    width: 100%;
    display: flex;
    letter-spacing: 0px;
    color: #9AA6B7;      
    font-size: 16px;
    font-weight: bold;
    span{
      margin-left: 40px;
      margin-right: 0px;
    }
  }
  .nav-link:hover {
    color: $colorTxt1;
    background-color:#EFF2FF ;
  }
  .nav-link.active {
    color: $primary;
    background-color: $colorBackground;
    span{
      color: $colorTxt1;
    }
  }
  .nav-link.active:hover {
    color: $secondary;
    background-color: #fff;
  }
  .sidebar-footer{
    background-color: #fff;
  }
  .sidebar-minimizer {
    display: none;
  }
}
/*********************************************************** main ***********************************************************/
.main{
  margin-left: 20% !important;
  background-color: $colorBackground;
  position: fixed;
  overflow: auto;
  bottom: 50px;
  right: 25px;
  top: 160px;
  border-radius: 0 0 40px 40px;
  padding: 50px;
  width: 80%;
  .main-footer{
    height: 50px;
    width: 100%;
    position: fixed;
    bottom: 0px;
    z-index: 1030;
    background-color: #fff;
  }
}
/*********************************************************** tunnel d'inscription ***********************************************************/
.links{
  cursor: pointer;
}
.links:hover{
  font-weight: bold;
}
.border-bottom{
  border-bottom: 1px solid;
  padding-bottom: 10px;
  margin-bottom: 40px;
  padding-left: 0px;
  font-size: 22px;
}

/**********************************************************************************************************************************/
/*********************************************************** responsive ***********************************************************/
/**********************************************************************************************************************************/
@media (max-width: 1440px) {
  .sidebar{
    width: 25% !important;
  }
  .main{
    width: 75%;
  }
  .app-header .navbar-nav {
    width: 75%;
  }
  .app-header .navbar-brand {
    width: 25%;
  }
}

@media (max-width: 1200px) {
  .container-fluid{
    padding: 0 0px !important;
  }
  .bloc-commun-style{
    padding: 30px;
    margin: 12px 0;
  }
  .image-container{
    padding:  0px 10px;
    img{
      width: 60px;
      height: 60px;
      border: 1px solid $colorTxt2;
      border-radius: 50px;
    }
  }
  .inputs{
    padding: 10px 15px;
    margin-bottom: 10px;
    margin: 0px;
    color: $colorTxt2;
    border-radius: 23px;
    border: 1px solid $colorTxt2;
    width: 100%;
  }
  .sidebar{
    .nav-link {
        font-size: 13px !important;
    }
  }
   .main-primary-title{
      font-size: 21px;
   }
   .main-secondary-title{
      padding-top: 20px;
      font-size: 16px !important;
   }
  .green-button {
    padding: 5px 40px 5px 40px;
  }
  .app-header .navbar-brand img {
    width: 130px; 
  }
  .payment-button{
    padding: 5px 10px 5px 10px;
  }
  .prix{
    font-size: 50px !important;
  }
  .blocs-results{
    span{
      font-size: 50px;
    }
  }
  .bloc-main-head{
    margin: 0 12px 12px 0px;
  }
  .avancemt-block{
    p {
      span {
        font-size: 55px !important;
      }
    }
  }
  .icons-and-infos{
    justify-content: space-between;
    padding-left: 0px !important;
    padding-top: 0px !important;
  }
  .icons-and-infos span{
    padding-left: 20px;
  }
  .permis-title{
    font-size: 16px;
    font-weight: bold;
  }
  .bloc-simulation{
    padding: 30px;
    h3{
      font-size: 14px;
    }
    div{
      padding-top: 5px;
      padding-bottom: 5px;
    }
    label{
      padding-left: 5px;
      font-size: 10px;
      vertical-align: super;
      text-align: justify;
    }
  }
  .horraire-table{
    text-align: center;
    tr {
      display: grid;
      width: 100%;
    }
    tr td{
      padding: 5px;
      width: 100%;
    }
    tr td:first-child{
      border-right: 0px solid #9AA6B7;
    }
    tr td:last-child{
      padding-left: 5px;
    }
  }
  .hide-table-responsive{
    display: none;
  }
  .show-table-responsive{
    display: table;
  }
  .profil-form{
    padding: 20px !important;
  }
  .agencies-list{
    p{
      font-size: 14px;
    }
  }
  .padding-responsive{
    padding-left: 15px;
    padding-right: 15px;
  }
  .image-offer{
    img {
      max-width: 200px;
      width: auto;
      height: 140px;
    }
  }
  .alert-button{
    margin-left: 0px;
    padding: 5px;
    margin-top: 20px;
  }
  .download-button{
    padding: 5px 10px 5px 10px;
  }
  .blocs-main-title{
    font-size: 14px;
  }
  .blocs-secondary-title{
    font-size: 12px;
    padding-left: 20px !important;
  }
  .bloc-left-payement{
    h2 { font-size: 14px; font-weight: bold;}
    h3 { font-size: 11px;}
  }
  .bloc-achat{
    padding: 20px !important;
    .blocs-main-title{
      padding-left: 0 !important;
    }
    ul{
      padding: 10px 0 10px 0;
      font-size: 12px;
    }
    li{
      list-style: none;
      padding-bottom: 16px;
    }
    li span{
      padding-left: 5px;
    }
    p{
      text-align: center;
    }
    .prix{
      font-size: 30px !important;
    }
  }
  .bloc-achat-hours .bloc-main-secondary-head .blocs-main-title{
    font-size: 12px !important;
    text-transform: lowercase;
    text-align: center;
    padding-left: 0px !important;
  }
  
  /******************/

}
@media (max-width: 800px) {
  .justify-content-center{
    padding: 20px !important;
  }
  .padding-x-responsive{
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .padding-left-responsive{
    padding-left: 0px;
  }
  .padding-top-responsive{
    padding-top: 20px;
  }
  .span-padding{
    padding-bottom: 5px;
  }
  // commun 
  .app-body {
    margin-top: 70px !important;
  }
  .app-header{
    height: 70px;
    right: 0px;
    border: 0;
  }
  .app-header .navbar-brand img {
    width: 110px; 
  }
  .app-header .navbar-toggler {
    display: block;
  }
  .app-header .navbar-nav {
    background-color: transparent;
    height: 70px;
    padding: 0;
    margin: 0px;
    border-radius: 0px;
  }
  html:not([dir="rtl"]) .sidebar {
    margin-left: -100%;
  }
  .app-header .navbar-brand {
    width: 155px;
    height: 100%;
    background-color: transparent;
  }
  .sidebar {
    width: 50% !important;
    height: 100% !important;
    left: 0;
    padding-left: 0px;
  }
    .main{
      margin: 0px !important;
      padding: 0px !important;
      position: initial;
      border-radius: 0;
      width: 100%;
      .main-footer{
        height: 0px;
        width: 100%;
        position: fixed;
        bottom: 0px;
        z-index: 1030;
        background-color: #fff;
      }
    }
  .bloc-application-mobile{
    display: none;
  }
  .bloc-main-head{
    display: block;
  }
  .d-flex{
    display: block !important;
  }
  .green-button {
    padding: 5px 40px 5px 40px;
  }
  .prix{
    font-size: 50px !important;
  }
  .main-primary-title{
    font-size: 22px;
  }
  .main-secondary-title{
    padding-top: 20px;
    font-size: 17px !important;
  }
  .blocs-results{
    span{
      font-size: 50px;
    }
  }
  .show-responsive{
    display: block;
  }
  .hide-responsive{
    display: none;
  }
  .blocs-main-title{
    padding-left: 0px !important;
  }
  .blocs-secondary-title{
    padding-left: 0px !important;
  }
  .display-responsive{
    display: block;
  }
  .payment-button{
    padding: 5px 10px 5px 10px;
  }
  .bloc-main-head{
    margin: 0 0 0px 0;
  }
  .with-border-left div{
    display: none;
  }
  .avancemt-block{
    display: none;
  }
  .bloc-start-end-formation div{
    padding-left: 0px;
  }
  .stage-bloc{
    text-align: center;
  }
  .icons-and-infos{
    justify-content: space-between;
    padding-left: 0px !important;
    padding-top: 0px !important;
  }
  .icons-and-infos span{
    padding-left: 20px;
  }
  .blocs-link{
    margin-top: 50px;
  }
  .permis-title{
    font-size: 16px;
    font-weight: bold;
  }
  .bloc-simulation{
    padding: 30px;
    h3{
      font-size: 14px;
    }
    div{
      padding-top: 5px;
      padding-bottom: 5px;
    }
    label{
      padding-left: 5px;
      font-size: 10px;
      vertical-align: super;
      text-align: justify;
    }
  }
  .bloc-main-head{
    margin-bottom: 20px;
  }
  .horraire-table{
    text-align: center;
    tr {
      display: grid;
      width: 100%;
    }
    tr td{
      padding: 5px;
      width: 100%;
    }
    tr td:first-child{
      border-right: 0px solid #9AA6B7;
    }
    tr td:last-child{
      padding-left: 5px;
    }
  }
  .hide-table-responsive{
    display: none;
  }
  .show-table-responsive{
    display: table;
  }
  .profil-form{
    padding: 20px !important;
  }
  //toggle in header
  .toggle-in-header{
  p{
    text-align: left;
    padding-left: 10px;
  }
  }
  .blocs-normal-text{
    font-size: 18px;
  }
  .agencies-list{
    p{
      font-size: 14px;
    }
  }
  .reverse-responsive{
    flex-direction: column-reverse;
  }
  .padding-responsive{
    padding-left: 15px;
    padding-right: 15px;
  }
  .payment-is-stage p{
    margin-top: 20px;
  }
  .image-offer{
    img {
      max-width: 200px;
      width: auto;
      height: 140px;
    }
  }
  .alert-button{
    margin-left: 0px;
    padding: 5px;
    margin-top: 20px;
  }
  .d-flex-bloc {
    display: inline-flex !important;  
  } 
}

.pad{
  margin-top: 3px;
}

@media (max-width: 512px) {
  .sidebar{
    width: 70% !important;
  }
}

.catSelect{
  background: transparent linear-gradient(46deg, #281B59 0%, #C10058 100%) 0% 0%;
  border-radius: 20px;
}
.agencies-list{
  border-radius: 50px;
  padding: 20px;
  margin: 20px 0;
}
/**********************************send documents form*****************************************/
.uploaded-file{
  background-color: $colorBackground;
}

.icon-file-input{
  padding-top: 6px;
  padding-left: 20px;
}
.icon-file-input:hover{
  cursor: pointer;
  opacity: 0.4;
}

.cut-content{
  overflow: hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  display:inline-block;
}

.send-file-form{
  padding-left: 20px;
  padding-right: 20px;
}