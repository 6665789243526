@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import "variables";

.popupModel{
    text-align: center !important;
    top: 25%;
    margin-top: -50px;
}
.modal-content{
    border-radius: 20px;
}
.popupModel-secondary-title{
    font-size: 16px;
    font-weight: bold;
    color: $colorTxt2;
}
.save-button{
    font-weight: bold;
    font-size: 18px;
}
.popupModel-link{
    text-decoration: underline $colorTxt2;
}
.linkCursor{
    cursor: pointer;
}
.linkCursor:hover{
    color: #582900;
}
.formuleContent{
    display: flex;
    //padding: 15px 0px;
}
.elemntsFormule{
   display: flex;
   align-items: center;
}
.angleRightIcon{
    margin-left: auto;
}
.buttonDiv{
    display: flex !important;
    cursor: pointer;
}
.buttonDivDisable{
    display: flex !important;
    background-color: #DCDCDC;
    cursor: grab;
}
.divButtonText{
    font-size: 28px;
    font-weight: 500;
    margin-left: 20px;
}
.inscriptionANTS{
    display: flex;
    background: transparent linear-gradient(46deg, #281B59 0%, #C10058 100%) 0% 0% !important;
}
.inscriptionANTS-elt{
    display: flex;
    align-items: center;
}
.inscriptionANTS-elt-radio{
    margin-right: 30px;
}
.inscriptionANTS-elt-text{
    color: #fff;
    font-weight: bold;
    font-size: 18px;
}
.backInputsRegistration{
    background-color: #EFF2FF;
}
.conditionConnexion{
    background: #fff !important;
    margin-top: 20px;
    .inscriptionANTS-elt-radio{
        margin-right: 10px !important;
    }
}
.information-text{
    font-weight: bold;
    font-size: 18px;
}
.evalLi{
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;
    li{
        margin-bottom: 10px;
    }
    span{
        margin-left: 10px;
    }
}
.eval-block-title{
    font-size: 25px;
    //font-family: 'Montserrat', sans-serif;
    //color: #1F3149;
    //opacity: 1;
}
.eval-cat-block{
    align-items: baseline !important;
}
.eval-icons-block{
    margin-right: 10px;
}
.eval-cat-block p{
    margin-bottom: 20px;
}
.sacPanier{
    position: relative;
    z-index: 1;
}
.nombreAchatPanier{
    position: absolute;
    z-index: 2;
    margin-left: -12px;
    margin-top: -12px;
}
.panier{
    background-color: #fff;
    border-radius: 20px;
    align-items: center;
    opacity: 1;
    word-break: break-word;
    display: flex;  
}
.modalBody{
    padding: 20px 0px;
}
.ignorerButton button{
    background: #EFF2FF;
    color: #1F3149;
    border: 1px solid #EFF2FF;
}
.block-offerPriceMessage{
    margin-top: -25px;
}
.offerPriceMessage{
    //display: flex;
    //align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: #C10058;
    text-align: center;
    margin-top: -10px;
}
hr{
    width: 40px;
    height: 2px;
    padding: 0px !important;
    background-color: #1F3149;
    //margin-left: -40px;
    transform: rotate(-45deg);
    margin-top: -15px;
    margin-left: 55% !important;
}
.elementPnaier{
    //border-bottom: 1px solid #1F3149;
    border-top: 1px solid #1F3149;
    padding: 20px 0px;
}
.placHolDate::before{
    content: attr(placeholder)" ";
    white-space: pre;
}
.placHolDate:focus::before {
    content:""!important;
}
.describeText{
    margin-left: 20px;
}
.img_pictos img{
    width: 80px;
    height: 80px;
}
.offreExc_sousTitre{
    font-weight: 600;
    font-size: 18px;
    color: #1F3149;
}
.eval_img_picto img{
    height: auto;
    width: 30px;
}
.pictoMenu{
    padding: 10px 10px;
    margin: 0px;
    cursor: pointer;
  }
.pictoMenu img{
    height: auto;
    width: 25px;
}
.newMenuAutMotBat{
    background-color: #fff;
    border-radius: 20px;
    align-items: center;
    opacity: 1;
    word-break: break-word;
    display: flex;
}
.panierText{
    margin-left: 10px;
    font-weight: bold;
}
.evalResult{
    margin-top: 20px;
    margin-bottom: 20px;
}
.evalElementResult{
    background-color: #EFF2FF;
    padding: 15px;
    font-size: 18px;
    .evalSpanResult{
        float: right;
        font-weight: bold;
    }
}
.offreExcDiv{
    margin-top: 15px;
}
.offreExcButton{
    margin-top: 50px;
}
.regiErrorCheck{
    display: flex;
    margin-left: 40px;
}
.deleteOfferFromPanier{
    float: right;
    cursor: pointer;
}
.offerExcPutGlasses{
    display: flex;
    align-items: baseline;
    h5{
        margin-right: 10px;
    }
}
.catButton{
    cursor: pointer;
}
.catButtonSelected{
    background: transparent linear-gradient(46deg, #281B59 0%, #C10058 100%) 0% 0%;
    color: #fff;
    font-weight: bold;
}
.NONbutton{
    background: #EFF2FF !important;
    color: #1F3149 !important;
    border: 1px solid #EFF2FF !important;
}
.mesAchatsButton{
    float: right;
    padding-left: 10px;
    padding-right: 10px;
}
.react-datepicker-wrapper{
    width: 100%;
}
.hearderPanier{
    font-weight: 600;
    font-size: 18px;
    padding: 10px 0px;
}
.calendly_iframe{
    height: 950px;
    width: 100%;
    border: none;
    border-radius: 20px;
}
/* responsive (verion tablette et mobile */
@media (max-width: 800px){
    .newMenuAutMotBat{
        display: none;
    }
    .panierText{
        display: none;
    }
    .marginInput{
        margin-bottom: 1rem;
    }
}
@media (max-width: 767px){
    .ignorerButton{
        margin-top: 20px;
    }
    /* .buttonDiv{
        display: -webkit-inline-box !important;
    } */
}
@media (max-width: 440px){
    /* .buttonDiv{
        display: -webkit-inline-box !important;
    } */
    .img_pictos img{
        width: 60px;
        height: 60px;
    }
    .divButtonText{
        font-size: 26px;
    }
}
@media (min-width: 800px) {
    .annulationAchat{
        width: 100%;
        margin-top: -30%;
        position: absolute;
    }
}